html {
  
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  
  /* -webkit-text-size-adjust: 100%; */
}


body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
  background-color: #fff;
  color: #000000; /* blueGrey[700] */
  line-height: 1.4;
  letter-spacing: .03ex;

}

a {
  cursor: pointer;
}